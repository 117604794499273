package xyz.qwewqa.relive.simulator.core.presets.condition

val conditions = listOf(
    FlowerOnlyCondition,
    WindOnlyCondition,
    SnowOnlyCondition,
    MoonOnlyCondition,
    SpaceOnlyCondition,
    CloudOnlyCondition,
    DreamOnlyCondition,
    NormalDamageOnlyCondition,
    SpecialDamageOnlyCondition,
    FrontOnlyCondition,
    MiddleOnlyCondition,
    BackOnlyCondition,
    SeishoOnlyCondition,
    RinmeikanOnlyCondition,
    FrontierOnlyCondition,
    SiegfeldOnlyCondition,
    SeiranOnlyCondition,
    KarenOnlyCondition,
    HikariOnlyCondition,
    MahiruOnlyCondition,
    ClaudineOnlyCondition,
    MayaOnlyCondition,
    JunnaOnlyCondition,
    NanaOnlyCondition,
    FutabaOnlyCondition,
    KaorukoOnlyCondition,
    TamaoOnlyCondition,
    IchieOnlyCondition,
    FumiOnlyCondition,
    RuiOnlyCondition,
    YuyukoOnlyCondition,
    AruruOnlyCondition,
    MisoraOnlyCondition,
    LalafinOnlyCondition,
    TsukasaOnlyCondition,
    ShizuhaOnlyCondition,
    AkiraOnlyCondition,
    MichiruOnlyCondition,
    MeiFanOnlyCondition,
    ShioriOnlyCondition,
    YachiyoOnlyCondition,
    KoharuOnlyCondition,
    SuzuOnlyCondition,
    HisameOnlyCondition,
).associateBy { it.name }
